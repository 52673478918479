/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import 'leaflet/dist/leaflet.css'
import React, { useEffect, useState } from 'react'
import { Marker, Popup, TileLayer, MapContainer, Tooltip } from 'react-leaflet'
import Leaflet from 'leaflet'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
// import { fetchLocalMapBox } from '../../services/apiMapBox'
import { InfosPeople } from './InfosPeople'
import female from '../../Assets/female.svg'
import male from '../../Assets/male.svg'
import pin from '../../Assets/pin.svg'
import pinLight from '../../Assets/pinCustom.svg'
import { useApi } from '../../hooks/useApi'
import useLoadingCenso from '../../hooks/useLoadingCenso'

const initialPosition = { lat: -9.883124, lng: -36.8276831 }

const maleIcon = Leaflet.icon({
  iconUrl: male,
  iconSize: [28, 28],
  // iconAnchor: [0, 0],
  popupAnchor: [0, -10],
})
const femaleIcon = Leaflet.icon({
  iconUrl: female,
  iconSize: [28, 28],
  // iconAnchor: [-29, -68],
  popupAnchor: [0, -10],
})
const mapPin = Leaflet.icon({
  iconUrl: pin,
  iconSize: [28, 28],
  // iconAnchor: [-29, -68],
  popupAnchor: [0, -10],
})
const mapPinLight = Leaflet.icon({
  iconUrl: pinLight,
  iconSize: [28, 28],
  // iconAnchor: [-29, -68],
  popupAnchor: [0, -10],
})

type DataPerson = {
  data?: any
  total?: number
}

const RendaFamiliar = [
  'Menos de 1 Salário Mínimo',
  '1 Salário mínimo',
  '1 a 3 Salários mínimo',
  '3 a 6 Salários mínimo',
  '6 a 10 Salários mínimo',
  'Superior a 10 S.M.',
  'Liquida anual (agricultor)',
]

const Internet = ['Telefone Móvel', 'Fibra Óptica', 'Via Rádio', 'Via Satélite']

const deficiencia = [
  'Mental',
  'Fisica',
  'Saude Mental',
  'Saude Visual',
  'Epilepsia',
  'Especial',
  'Outros',
]

const material = [
  'Alvenaria/tijolos com revestimento',
  'Alvenaria/tijolos sem revestimento',
  'Madeira aparelhada',
  'Taipa',
  'Palha',
  'Materiais aproveitados – lona, retalhos de madeira e ferro, etc.',
  'Outros materiais',
]

export function Map() {
  const globalMap = `https://api.mapbox.com/styles/v1/rodrigohigino/cl94txjim000s15nsdbte4by1/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_ACCESS_TOKEN_MAP_BOX}`
  // const lightMap = `https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_ACCESS_TOKEN_MAP_BOX}`
  // const darkMap = `https://api.mapbox.com/styles/v1/mapbox/dark-v10/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_ACCESS_TOKEN_MAP_BOX}`
  const { setLoadingCenso, renderLoading } = useLoadingCenso()
  const api = useApi()
  const defaultFilter: any[] = [
    // {
    //   field: 'familia.deficiencia.tipoDeficiencia',
    //   filter: 'Outros',
    // },
  ]
  const [typeMap, setTypeMap] = useState(globalMap)
  const [iconMap, setIconMap] = useState(mapPinLight)
  // const [typeMapSelected, setTypeMapSelected] = useState('global')
  const [location, setLocation] = useState(initialPosition)
  const [primaryFilter, setPrimaryFilter] = useState('')
  const [secondaryFilter, setSecondaryFilter] = useState('')
  const [filter, setFilter] = useState(defaultFilter)
  const [dataPerson, setDataPerson] = useState<DataPerson>()

  useEffect(() => {
    ;(async () => {
      setLoadingCenso(true)
      const response = await api.person(filter)
      console.log(response)
      setDataPerson(response)
      setLoadingCenso(false)
    })()
  }, [filter])

  const handlePrimaryFilter = (event: SelectChangeEvent) => {
    setSecondaryFilter('')
    setPrimaryFilter(event.target.value)
    const newFilter = [
      {
        field: event.target.value,
        filter: 'Todos',
      },
    ]
    setFilter(newFilter)
  }

  const filterSet = (event: SelectChangeEvent, newFilter: any) => {
    if (!event.target.value) {
      const filterDefault = [
        {
          field: primaryFilter,
          filter: 'Todos',
        },
      ]
      setFilter(filterDefault)
    } else {
      setFilter(newFilter)
    }
  }

  const handleSecondaryFilter = (event: SelectChangeEvent) => {
    setSecondaryFilter(event.target.value)
    if (primaryFilter === 'infra.possuiInternet.tipo') {
      const newFilter = [
        {
          field: 'infra.possuiInternet.tipo',
          filter: event.target.value,
        },
      ]
      filterSet(event, newFilter)
    }
    if (primaryFilter === 'familia.rendaMensal') {
      const newFilter = [
        {
          field: 'familia.rendaMensal',
          filter: event.target.value,
        },
      ]
      filterSet(event, newFilter)
    }
    if (primaryFilter === 'familia.deficiencia.tipoDeficiencia') {
      const newFilter = [
        {
          field: 'familia.deficiencia.tipoDeficiencia',
          filter: event.target.value,
        },
      ]
      filterSet(event, newFilter)
    }
    if (primaryFilter === 'infra.materialPredominante') {
      const newFilter = [
        {
          field: 'infra.materialPredominante',
          filter: event.target.value,
        },
      ]
      filterSet(event, newFilter)
    }
  }

  // const handleSelectedMap = (event: SelectChangeEvent) => {
  //   setTypeMapSelected(event.target.value)
  //   if (event.target.value === 'global') {
  //     setTypeMap(globalMap)
  //     setIconMap(mapPinLight)
  //   } else if (event.target.value === 'light') {
  //     setTypeMap(lightMap)
  //     setIconMap(mapPin)
  //   } else if (event.target.value === 'dark') {
  //     setTypeMap(darkMap)
  //     setIconMap(mapPin)
  //   }
  // }

  return (
    <>
      {renderLoading}
      <div className="flex w-full mb-2 items-center justify-between">
        <div className="flex items-center">
          <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
            <InputLabel id="demo-select-small">Filtro</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={primaryFilter}
              label="Filtro"
              onChange={handlePrimaryFilter}
            >
              <MenuItem value="">
                <em>Nenhum</em>
              </MenuItem>
              <MenuItem value="familia.deficiencia.tipoDeficiencia">
                Familiar com deficiência
              </MenuItem>
              <MenuItem value="infra.possuiInternet.tipo">
                Possui internet
              </MenuItem>
              <MenuItem value="familia.rendaMensal">Renda Familiar</MenuItem>
              <MenuItem value="infra.materialPredominante">
                Material Predominante
              </MenuItem>
            </Select>
          </FormControl>
          {primaryFilter === 'infra.possuiInternet.tipo' ? (
            <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
              <InputLabel id="internetId">Filtro</InputLabel>
              <Select
                labelId="internetId"
                id="internetId"
                value={secondaryFilter}
                label="Filtro"
                onChange={handleSecondaryFilter}
              >
                <MenuItem value="">
                  <em>Nenhum</em>
                </MenuItem>
                {Internet.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : primaryFilter === 'familia.rendaMensal' ? (
            <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
              <InputLabel id="rendaFamiliarId">Filtro</InputLabel>
              <Select
                labelId="rendaFamiliarId"
                id="rendaFamiliarId"
                value={secondaryFilter}
                label="Filtro"
                onChange={handleSecondaryFilter}
              >
                <MenuItem value="">
                  <em>Nenhum</em>
                </MenuItem>
                {RendaFamiliar.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : primaryFilter === 'familia.deficiencia.tipoDeficiencia' ? (
            <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
              <InputLabel id="deficienciaTipoId">Filtro</InputLabel>
              <Select
                labelId="deficienciaTipoId"
                id="deficienciaTipoId"
                value={secondaryFilter}
                label="Filtro"
                onChange={handleSecondaryFilter}
              >
                <MenuItem value="">
                  <em>Nenhum</em>
                </MenuItem>
                {deficiencia.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : primaryFilter === 'infra.materialPredominante' ? (
            <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
              <InputLabel id="materialId">Filtro</InputLabel>
              <Select
                labelId="materialId"
                id="materialId"
                value={secondaryFilter}
                label="Filtro"
                onChange={handleSecondaryFilter}
              >
                <MenuItem value="">
                  <em>Nenhum</em>
                </MenuItem>
                {material.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : null}
        </div>
        {/* <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
          <InputLabel id="demo-select-small">Tipo do Mapa</InputLabel>
          <Select
            labelId="demo-select-small"
            id="demo-select-small"
            value={typeMapSelected}
            label="Tipo do Mapa"
            onChange={handleSelectedMap}
          >
            <MenuItem value="global">Global</MenuItem>
            <MenuItem value="light">Claro</MenuItem>
            <MenuItem value="dark">Escuro</MenuItem>
          </Select>
        </FormControl> */}
      </div>
      <div className="flex gap-1 mb-2">
        <span className="font-semibold">Total de famílias:</span>
        <span>{dataPerson ? dataPerson.total || 0 : 0}</span>
      </div>
      <div id="map" className="flex justify-center">
        <MapContainer
          center={location}
          zoom={14}
          style={{ width: '100%', height: '100%', maxWidth: '1600px' }}
        >
          <TileLayer url={typeMap} />
          {dataPerson
            ? dataPerson?.data.map((data: any, index: any) => (
                <Marker
                  key={index}
                  icon={iconMap}
                  position={[
                    data.cadastro.location.coords.latitude,
                    data.cadastro.location.coords.longitude,
                  ]}
                >
                  <Popup
                    closeButton={false}
                    minWidth={240}
                    className="map-popup"
                  >
                    <div className="flex flex-col">
                      <h3 className="font-semibold text-lg">
                        {data.cadastro.nomeCompleto}
                      </h3>
                      <span className="text-lg">{data.address}</span>
                      <span className="text-md mb-5">
                        {data.familia.familiares.length === 0
                          ? 'Não possui familiares residentes'
                          : data.familia.familiares.length === 1
                          ? 'Contém 1 familiar residente'
                          : `Contém ${data.familia.familiares.length} familiares residentes`}
                      </span>
                      <InfosPeople data={data} />
                    </div>
                  </Popup>
                  <Tooltip>{data.cadastro.nomeCompleto}</Tooltip>
                </Marker>
              )) || null
            : null}
        </MapContainer>
      </div>
    </>
  )
}
