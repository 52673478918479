import React from 'react'
// import Link from '@mui/material/Link'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TitleComponent from '../../../../Utils/TitleComponent'

// Generate Order Data
function createData(
  id: number,
  date: string,
  name: string,
  shipTo: string,
  paymentMethod: number,
  amount: string,
) {
  return { id, date, name, shipTo, paymentMethod, amount }
}

const rows = [
  createData(0, '16 Out, 2022', 'Jose Silva', 'Algodão', 3, 'José'),
  createData(1, '16 Out, 2022', 'Fernanda lim', 'Algodão', 4, 'Maria'),
  createData(2, '16 Out, 2022', 'Maria das dores', 'Algodão', 6, 'José'),
  createData(3, '16 Out, 2022', 'Josivaldo jose', 'Algodão', 2, 'José'),
  createData(4, '15 Out, 2022', 'Luiz Santos', 'Algodão', 7, 'Maria'),
]

// function preventDefault(event: React.MouseEvent) {
//   event.preventDefault()
// }

export default function TableRegister() {
  return (
    <>
      <TitleComponent>Últimos cadastros</TitleComponent>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center">Data do cadastro</TableCell>
            <TableCell align="center">Nome</TableCell>
            <TableCell align="center">Endereço</TableCell>
            <TableCell align="center">Membros da família</TableCell>
            <TableCell align="center">Cadastrado Por</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell align="center">{row.date}</TableCell>
              <TableCell align="center">{row.name}</TableCell>
              <TableCell align="center">{row.shipTo}</TableCell>
              <TableCell align="center">{row.paymentMethod}</TableCell>
              <TableCell align="center">{row.amount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {/* <Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
        See more orders
      </Link> */}
    </>
  )
}
