import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { faker } from '@faker-js/faker'
import TitleComponent from '../../Utils/TitleComponent'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
  },
}

const labels = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul']

export const data = {
  labels,
  datasets: [
    {
      label: 'Famílias',
      data: labels.map(() => faker.datatype.number({ min: 0, max: 200 })),
      backgroundColor: 'rgba(62, 82, 131, 0.8)',
    },
    // {
    //   label: 'Dataset 2',
    //   data: labels.map(() => faker.datatype.number({ min: 0, max: 200 })),
    //   backgroundColor: 'rgba(22, 163, 71, 0.5)',
    // },
  ],
}

export function ChartRef() {
  return (
    <>
      <TitleComponent>Cadastros por mês</TitleComponent>
      <div className="w-full px-32">
        <Bar options={options} data={data} />
      </div>
    </>
  )
}
