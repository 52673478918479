/* eslint-disable react/require-default-props */
import { Paper } from '@mui/material'
import React from 'react'
import TotalRegister from '../TotalRegister'

interface ICardProps {
  children?: React.ReactNode
  question: string
  total: string
}

export function Card({ question, total, children }: ICardProps) {
  return (
    <Paper
      sx={{
        p: 2,
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        height: 180,
        minWidth: '250px',
      }}
    >
      <TotalRegister
        title={question || 'Question is not defined!'}
        total={total || 'Total is not defined!'}
      />
      {children}
    </Paper>
  )
}
