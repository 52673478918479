import React from 'react'
import { RelatorioGeral } from '../../Components/Relatorios/RelatorioGeral'
import BaseLayout from '../../template/BaseLayout'

export function Relatorios() {
  return (
    <BaseLayout>
      <RelatorioGeral />
    </BaseLayout>
  )
}
