export interface Family {
  nomeCompletoFamiliar: string | undefined
  cpfFamiliar: string | undefined
  nomeDaMaeFamiliar: string | undefined
  dataNascimentoFamiliar: string | undefined
  grauEscolaridadeFamiliar: string | undefined
  parentescoFamiliar: string | undefined
  generoFamiliar: string | undefined
  identidadeSexualFamiliar: string | undefined
  trabalhoFamiliar: string | undefined
}

export type FormSteps = {
  nomeCompleto: string
  dataNascimento: string
  nomeMae: string
  nomePai: string
  apelido: string
  cpf: string
  rg: string
  orgaoExpedidor: string
  nis: string
  cartaoSus: string
  ubs: string
  email: string
  telefone: string
  grauEscolaridade: string
  endereco: string
  numero: string
  complemento: string
  bairro: string
  pontoReferencia: string
  situacaoImovel: string
  imovelRuralUrbano: string
  family: Family[]
  quantosMoraCasa: string
  rendaMensal: string
  deficiencia: string
  recebeProgramasSociais: string
  possuiTransporte: string
  pertenceGrupoTradicional: string
  familiasImovel: string
  imovelFamiliaStep3: string
  casaConstruidaStep3: string
  materialParedeStep3: string
  numeroComodosImovelStep3: string
  estadoConservacaoStep3: string
  dormirSalaStep3: string
  possuiEnergiaStep3: string
  precisaReformaStep3: string
  areaSujeitaStep3: string
  existeCalcamentoStep3: string
  possuiAcessibiStep3: string
  possuiInternetStep3: string
  possuiBanheiroStep3: string
  banheiroForaStep3: string
  escoamentoStep3: string
  esgotoStep3: string
  domesticoLixoStep3: string
  abastecimentoStep3: string
  recebeAguaPipaStep3: string
  precisaMotorPipaStep3: string
  capacidadePipaStep3: string
  cisternaRecursosStep3: string
  cisternaRachadurasStep3: string
  cisternaNumeracaoStep3: string
  captacaoAguaStep3: string
  cisternaFamiliaStep3: string
  qntsVezesComemDiaStep4: string
  compraAlimentosStep4: string
  preocupaAcabarComidaStep4: string
  comidaAcabouAntesStep4: string
  diminuiuQntAlimentoStep4: string
  sentiuFomeNaoSuficienteStep4: string
  perdeuPesoComidaSuficiStep4: string
  diaIntSemComerStep4: string
  comeuMenosNaoDinheiroStep4: string
  poucosAlimenDinheiroAcabouStep4: string
  alimenSaudavelSemDinheiroStep4: string
  qualidadeAliemntacaoStep4: string
  passouFomeUltim10anosStep4: string
  cafeDaManhaStep4: string
  almocoStep4: string
  jantarStep4: string
  deixouDeComerGasAcabouStep4: string
  alimentoCozidoEmStep4: string
  consumirFrutasStep4: string
  comeProteinaStep4: string
  animaisTiposStep5: string
  animaisProdutosStep5: string
  terrenoCaracterizacaoStep5: string
  terrenoMetragemStep5: string
  plantacaoTiposStep5: string
  plantacaoDocsStep5: string
  sementeStep5: string
  cursoQualStep6: string
  cursoAreaStep6: string
  protecaoSocialStep7: string
  qntsAnosMoraEstadoStep8: string
  sempreMorouEstadoStep8: string
  qntsAnosMoraMunicipioStep8: string
  sempreMorouMunicipioStep8: string
  qntsAnosMoraBairroStep8: string
  sempreMorouBairroStep8: string
  parentesProximosMoradiaStep8: string
  vizinhosRedeApoioStep8: string
  gruposReligiososStep8: string
  movimentosSociaisStep8: string
  familiaCadastrCRAsStep8: string
  familiaCadastrCREAsStep8: string
  familiaProgramasStep8: string
}

export const initialValuesState = {
  nomeCompleto: 'Dado não informado',
  dataNascimento: 'Dado não informado',
  nomeMae: 'Dado não informado',
  nomePai: 'Dado não informado',
  apelido: 'Dado não informado',
  cpf: 'Dado não informado',
  rg: 'Dado não informado',
  orgaoExpedidor: 'Dado não informado',
  nis: 'Dado não informado',
  cartaoSus: 'Dado não informado',
  ubs: 'Dado não informado',
  email: 'Dado não informado',
  telefone: 'Dado não informado',
  grauEscolaridade: 'Dado não informado',
  endereco: 'Dado não informado',
  numero: 'Dado não informado',
  complemento: 'Dado não informado',
  bairro: 'Dado não informado',
  pontoReferencia: 'Dado não informado',
  situacaoImovel: 'Dado não informado',
  imovelRuralUrbano: 'Dado não informado',
  quantosMoraCasa: 'Dado não informado',
  rendaMensal: 'Dado não informado',
  deficiencia: 'Dado não informado',
  recebeProgramasSociais: 'Dado não informado',
  possuiTransporte: 'Dado não informado',
  pertenceGrupoTradicional: 'Dado não informado',
  familiasImovel: 'Dado não informado',
  imovelFamiliaStep3: 'Dado não informado',
  casaConstruidaStep3: 'Dado não informado',
  materialParedeStep3: 'Dado não informado',
  numeroComodosImovelStep3: 'Dado não informado',
  estadoConservacaoStep3: 'Dado não informado',
  dormirSalaStep3: 'Dado não informado',
  possuiEnergiaStep3: 'Dado não informado',
  precisaReformaStep3: 'Dado não informado',
  areaSujeitaStep3: 'Dado não informado',
  existeCalcamentoStep3: 'Dado não informado',
  possuiAcessibiStep3: 'Dado não informado',
  possuiInternetStep3: 'Dado não informado',
  possuiBanheiroStep3: 'Dado não informado',
  banheiroForaStep3: 'Dado não informado',
  escoamentoStep3: 'Dado não informado',
  esgotoStep3: 'Dado não informado',
  domesticoLixoStep3: 'Dado não informado',
  abastecimentoStep3: 'Dado não informado',
  recebeAguaPipaStep3: 'Dado não informado',
  precisaMotorPipaStep3: 'Dado não informado',
  capacidadePipaStep3: 'Dado não informado',
  cisternaRecursosStep3: 'Dado não informado',
  cisternaRachadurasStep3: 'Dado não informado',
  cisternaNumeracaoStep3: 'Dado não informado',
  captacaoAguaStep3: 'Dado não informado',
  cisternaFamiliaStep3: 'Dado não informado',
  qntsVezesComemDiaStep4: 'Dado não informado',
  compraAlimentosStep4: 'Dado não informado',
  preocupaAcabarComidaStep4: 'Dado não informado',
  comidaAcabouAntesStep4: 'Dado não informado',
  diminuiuQntAlimentoStep4: 'Dado não informado',
  sentiuFomeNaoSuficienteStep4: 'Dado não informado',
  perdeuPesoComidaSuficiStep4: 'Dado não informado',
  diaIntSemComerStep4: 'Dado não informado',
  comeuMenosNaoDinheiroStep4: 'Dado não informado',
  poucosAlimenDinheiroAcabouStep4: 'Dado não informado',
  alimenSaudavelSemDinheiroStep4: 'Dado não informado',
  qualidadeAliemntacaoStep4: 'Dado não informado',
  passouFomeUltim10anosStep4: 'Dado não informado',
  cafeDaManhaStep4: 'Dado não informado',
  almocoStep4: 'Dado não informado',
  jantarStep4: 'Dado não informado',
  deixouDeComerGasAcabouStep4: 'Dado não informado',
  alimentoCozidoEmStep4: 'Dado não informado',
  consumirFrutasStep4: 'Dado não informado',
  comeProteinaStep4: 'Dado não informado',
  animaisTiposStep5: 'Dado não informado',
  animaisProdutosStep5: 'Dado não informado',
  terrenoCaracterizacaoStep5: 'Dado não informado',
  terrenoMetragemStep5: 'Dado não informado',
  plantacaoTiposStep5: 'Dado não informado',
  plantacaoDocsStep5: 'Dado não informado',
  sementeStep5: 'Dado não informado',
  cursoQualStep6: 'Dado não informado',
  cursoAreaStep6: 'Dado não informado',
  protecaoSocialStep7: 'Dado não informado',
  qntsAnosMoraEstadoStep8: 'Dado não informado',
  sempreMorouEstadoStep8: 'Dado não informado',
  qntsAnosMoraMunicipioStep8: 'Dado não informado',
  sempreMorouMunicipioStep8: 'Dado não informado',
  qntsAnosMoraBairroStep8: 'Dado não informado',
  sempreMorouBairroStep8: 'Dado não informado',
  parentesProximosMoradiaStep8: 'Dado não informado',
  vizinhosRedeApoioStep8: 'Dado não informado',
  gruposReligiososStep8: 'Dado não informado',
  movimentosSociaisStep8: 'Dado não informado',
  familiaCadastrCRAsStep8: 'Dado não informado',
  familiaCadastrCREAsStep8: 'Dado não informado',
  familiaProgramasStep8: 'Dado não informado',
}
