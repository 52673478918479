import { createContext } from 'react'
import { User } from '../../types/User'

export type AuthContextType = {
  user: User | null
  signin: (cpf: string, password: string) => void
  signout: () => void
  loadingAuth: boolean
}

export const AuthContext = createContext<AuthContextType>({} as AuthContextType)
