import api from '../services/api'

type filterType = {
  field: string
  filter: string
}

export const useApi = () => ({
  validateToken: async (token: string) => {
    const response = await api.get('/city', {
      headers: { Authorization: `Bearer${token}` },
    })
    return response.data
  },
  signin: async (cpf: string, password: string) => {
    try {
      const response = await api.post('/user/login', { cpf, password })
      return response.data
    } catch (error) {
      return error
    }
  },
  person: async (data: filterType[]) => {
    try {
      const response = await api.post('person/filter', data)
      return response.data
    } catch (error) {
      return error
    }
  },
  logout: async () => {
    return { status: true }
  },
})
