import React, { useEffect } from 'react'
import { Paper } from '@mui/material'
import useLoadingCenso from '../../hooks/useLoadingCenso'
import { ChartRef } from './ChartRef'
import { ChartPie } from './ChartPie'
import TotalRegister from './TotalRegister'

export function Home() {
  const { renderLoading, setLoadingCenso } = useLoadingCenso()

  useEffect(() => {
    setTimeout(() => {
      setLoadingCenso(false)
    }, 200)
  }, [])

  return (
    <>
      {renderLoading}
      <div className="flex flex-col w-full items-center justify-center gap-7">
        <div className="w-full max-w-screen-lg">
          <div className="w-full">
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <ChartRef />
            </Paper>
          </div>
        </div>
        <div className="flex gap-7 w-full max-w-screen-lg justify-between">
          <div className="w-full max-w-3xl">
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <ChartPie />
            </Paper>
          </div>
          <div className="flex flex-col gap-7 justify-center">
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                height: 180,
              }}
            >
              <TotalRegister
                isDataModified
                title="Total Cadastrado"
                total="442"
              />
            </Paper>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                height: 180,
              }}
            >
              <TotalRegister
                isDataModified
                title="Menos de 1 salário"
                total="195"
              />
            </Paper>
          </div>
        </div>
      </div>
    </>
  )
}
