import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'
import TitleComponent from '../../Utils/TitleComponent'

ChartJS.register(ArcElement, Tooltip, Legend)
export const options = {
  responsive: true,
}

export const data = {
  labels: ['Error', 'Ok'],
  datasets: [
    {
      label: '# of Votes',
      data: [2, 440],
      backgroundColor: ['rgba(255, 99, 132, 0.5)', 'rgba(22, 163, 74, 0.5)'],
      borderColor: ['rgba(255, 99, 132, 1)', 'rgba(22, 163, 74, 1)'],
      borderWidth: 1,
    },
  ],
}
export function ChartPie() {
  return (
    <>
      <TitleComponent>Cadastros com erro</TitleComponent>
      <div className="flex items-center w-full justify-center">
        <div className="w-4/12">
          <Pie data={data} options={options} />
        </div>
      </div>
    </>
  )
}
