import React, { useState } from 'react'
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Fab from '@mui/material/Fab/Fab'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import { green } from '@mui/material/colors'
import family from '../Assets/Icon.png'

const useLoadingCenso = () => {
  const [loading, setLoading] = useState(true)

  return {
    setLoadingCenso: setLoading,
    loadingCenso: loading,
    renderLoading: (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box sx={{ m: 1, position: 'relative' }}>
            <Fab color="inherit">
              <img src={family} alt="Familia" className="rounded-full" />
            </Fab>
            <CircularProgress
              size={68}
              sx={{
                color: green[500],
                position: 'absolute',
                top: -6,
                left: -6,
                zIndex: 1,
              }}
            />
          </Box>
        </Box>
      </Backdrop>
    ),
  }
}

export default useLoadingCenso
