/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { CircularProgress, TextField } from '@mui/material'
import React, { useContext, useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import family from '../../Assets/family.png'
import { AuthContext } from '../../contexts/Auth/AuthContext'

type ILoginProps = {
  user: string
  password: string
}

function Login() {
  const { signin, loadingAuth } = useContext(AuthContext)
  const initialValues = {
    user: '',
    password: '',
  }
  const { register, handleSubmit } = useForm<ILoginProps>({
    defaultValues: initialValues,
  })
  const onSubmit: SubmitHandler<ILoginProps> = (valueForm) => {
    if (valueForm.user !== '' && valueForm.password !== '') {
      signin(valueForm.user, valueForm.password)
    }
  }

  return (
    <div>
      <section className="bg-white dark:bg-gray-900 {-- h-screen --}">
        <div className="mx-auto flex justify-center md:items-center relative md:h-full">
          <div className="absolute top-0 right-0 pt-2 mr-4">
            <svg width={200} height={144} xmlns="http://www.w3.org/2000/svg">
              <g fill="#37b874" fillRule="evenodd">
                <rect width={4} height={4} rx={2} />
                <rect x={23} width={4} height={4} rx={2} />
                <rect x={45} width={4} height={4} rx={2} />
                <rect x={68} width={4} height={4} rx={2} />
                <rect x={90} width={4} height={4} rx={2} />
                <rect x={113} width={4} height={4} rx={2} />
                <rect x={135} width={4} height={4} rx={2} />
                <rect x={158} width={4} height={4} rx={2} />
                <rect x={181} width={4} height={4} rx={2} />
                <rect x={203} width={4} height={4} rx={2} />
                <rect y={18} width={4} height={4} rx={2} />
                <rect x={23} y={18} width={4} height={4} rx={2} />
                <rect x={45} y={18} width={4} height={4} rx={2} />
                <rect x={68} y={18} width={4} height={4} rx={2} />
                <rect x={90} y={18} width={4} height={4} rx={2} />
                <rect x={113} y={18} width={4} height={4} rx={2} />
                <rect x={135} y={18} width={4} height={4} rx={2} />
                <rect x={158} y={18} width={4} height={4} rx={2} />
                <rect x={181} y={18} width={4} height={4} rx={2} />
                <rect x={203} y={18} width={4} height={4} rx={2} />
                <rect y={35} width={4} height={4} rx={2} />
                <rect x={23} y={35} width={4} height={4} rx={2} />
                <rect x={45} y={35} width={4} height={4} rx={2} />
                <rect x={68} y={35} width={4} height={4} rx={2} />
                <rect x={90} y={35} width={4} height={4} rx={2} />
                <rect x={113} y={35} width={4} height={4} rx={2} />
                <rect x={135} y={35} width={4} height={4} rx={2} />
                <rect x={158} y={35} width={4} height={4} rx={2} />
                <rect x={181} y={35} width={4} height={4} rx={2} />
                <rect x={203} y={35} width={4} height={4} rx={2} />
                <rect y={53} width={4} height={4} rx={2} />
                <rect x={23} y={53} width={4} height={4} rx={2} />
                <rect x={45} y={53} width={4} height={4} rx={2} />
                <rect x={68} y={53} width={4} height={4} rx={2} />
                <rect x={90} y={53} width={4} height={4} rx={2} />
                <rect x={113} y={53} width={4} height={4} rx={2} />
                <rect x={135} y={53} width={4} height={4} rx={2} />
                <rect x={158} y={53} width={4} height={4} rx={2} />
                <rect x={181} y={53} width={4} height={4} rx={2} />
                <rect x={203} y={53} width={4} height={4} rx={2} />
                <rect y={70} width={4} height={4} rx={2} />
                <rect x={23} y={70} width={4} height={4} rx={2} />
                <rect x={45} y={70} width={4} height={4} rx={2} />
                <rect x={68} y={70} width={4} height={4} rx={2} />
                <rect x={90} y={70} width={4} height={4} rx={2} />
                <rect x={113} y={70} width={4} height={4} rx={2} />
                <rect x={135} y={70} width={4} height={4} rx={2} />
                <rect x={158} y={70} width={4} height={4} rx={2} />
                <rect x={181} y={70} width={4} height={4} rx={2} />
                <rect x={203} y={70} width={4} height={4} rx={2} />
                <rect y={88} width={4} height={4} rx={2} />
                <rect x={23} y={88} width={4} height={4} rx={2} />
                <rect x={45} y={88} width={4} height={4} rx={2} />
                <rect x={68} y={88} width={4} height={4} rx={2} />
                <rect x={90} y={88} width={4} height={4} rx={2} />
                <rect x={113} y={88} width={4} height={4} rx={2} />
                <rect x={135} y={88} width={4} height={4} rx={2} />
                <rect x={158} y={88} width={4} height={4} rx={2} />
                <rect x={181} y={88} width={4} height={4} rx={2} />
                <rect x={203} y={88} width={4} height={4} rx={2} />
                <rect y={105} width={4} height={4} rx={2} />
                <rect x={23} y={105} width={4} height={4} rx={2} />
                <rect x={45} y={105} width={4} height={4} rx={2} />
                <rect x={68} y={105} width={4} height={4} rx={2} />
                <rect x={90} y={105} width={4} height={4} rx={2} />
                <rect x={113} y={105} width={4} height={4} rx={2} />
                <rect x={135} y={105} width={4} height={4} rx={2} />
                <rect x={158} y={105} width={4} height={4} rx={2} />
                <rect x={181} y={105} width={4} height={4} rx={2} />
                <rect x={203} y={105} width={4} height={4} rx={2} />
                <rect y={123} width={4} height={4} rx={2} />
                <rect x={23} y={123} width={4} height={4} rx={2} />
                <rect x={45} y={123} width={4} height={4} rx={2} />
                <rect x={68} y={123} width={4} height={4} rx={2} />
                <rect x={90} y={123} width={4} height={4} rx={2} />
                <rect x={113} y={123} width={4} height={4} rx={2} />
                <rect x={135} y={123} width={4} height={4} rx={2} />
                <rect x={158} y={123} width={4} height={4} rx={2} />
                <rect x={181} y={123} width={4} height={4} rx={2} />
                <rect x={203} y={123} width={4} height={4} rx={2} />
                <rect y={140} width={4} height={4} rx={2} />
                <rect x={23} y={140} width={4} height={4} rx={2} />
                <rect x={45} y={140} width={4} height={4} rx={2} />
                <rect x={68} y={140} width={4} height={4} rx={2} />
                <rect x={90} y={140} width={4} height={4} rx={2} />
                <rect x={113} y={140} width={4} height={4} rx={2} />
                <rect x={135} y={140} width={4} height={4} rx={2} />
                <rect x={158} y={140} width={4} height={4} rx={2} />
                <rect x={181} y={140} width={4} height={4} rx={2} />
                <rect x={203} y={140} width={4} height={4} rx={2} />
              </g>
            </svg>
          </div>
          <form
            id="login"
            className="w-full sm:w-4/6 md:w-3/6 lg:w-4/12 xl:w-3/12 text-gray-800 mb-32 sm:mb-0 my-40 sm:my-12 px-2 sm:px-0"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="px-2 flex flex-col items-center justify-center mt-8 sm:mt-0">
              <img src={family} alt="Familia" className="w-50" width="100px" />
              <h2 className="text-4xl dark:text-gray-100 leading-tight pt-8 whitespace-nowrap">
                Censo Molde Manager
              </h2>
            </div>
            <div className="pt-16 px-2 flex flex-col items-center justify-center">
              <h3 className="text-2xl sm:text-3xl xl:text-2xl font-bold dark:text-gray-100 leading-tight">
                Entre com sua conta
              </h3>
            </div>
            <div className="mt-12 w-full px-2 sm:px-6">
              <div className="flex flex-col mt-5">
                <TextField
                  required
                  label="Usuário"
                  fullWidth
                  {...register('user')}
                />
              </div>
              <div className="flex flex-col mt-5">
                <TextField
                  required
                  label="Senha"
                  fullWidth
                  type="password"
                  {...register('password')}
                />
              </div>
            </div>
            <div className="px-2 mb-16 sm:mb-56 md:mb-16 sm:px-6">
              <button
                type="submit"
                className="focus:outline-none w-full bg-green-700 transition duration-150 ease-in-out hover:bg-green-600 rounded text-white px-8 py-3 text-sm mt-6"
                disabled={loadingAuth}
              >
                {loadingAuth ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  'Entrar'
                )}
              </button>
            </div>
          </form>
          <div className="absolute bottom-0 left-0 pb-2 ml-4">
            <svg width={200} height={144} xmlns="http://www.w3.org/2000/svg">
              <g fill="#37b874" fillRule="evenodd">
                <rect width={4} height={4} rx={2} />
                <rect x={23} width={4} height={4} rx={2} />
                <rect x={45} width={4} height={4} rx={2} />
                <rect x={68} width={4} height={4} rx={2} />
                <rect x={90} width={4} height={4} rx={2} />
                <rect x={113} width={4} height={4} rx={2} />
                <rect x={135} width={4} height={4} rx={2} />
                <rect x={158} width={4} height={4} rx={2} />
                <rect x={181} width={4} height={4} rx={2} />
                <rect x={203} width={4} height={4} rx={2} />
                <rect y={18} width={4} height={4} rx={2} />
                <rect x={23} y={18} width={4} height={4} rx={2} />
                <rect x={45} y={18} width={4} height={4} rx={2} />
                <rect x={68} y={18} width={4} height={4} rx={2} />
                <rect x={90} y={18} width={4} height={4} rx={2} />
                <rect x={113} y={18} width={4} height={4} rx={2} />
                <rect x={135} y={18} width={4} height={4} rx={2} />
                <rect x={158} y={18} width={4} height={4} rx={2} />
                <rect x={181} y={18} width={4} height={4} rx={2} />
                <rect x={203} y={18} width={4} height={4} rx={2} />
                <rect y={35} width={4} height={4} rx={2} />
                <rect x={23} y={35} width={4} height={4} rx={2} />
                <rect x={45} y={35} width={4} height={4} rx={2} />
                <rect x={68} y={35} width={4} height={4} rx={2} />
                <rect x={90} y={35} width={4} height={4} rx={2} />
                <rect x={113} y={35} width={4} height={4} rx={2} />
                <rect x={135} y={35} width={4} height={4} rx={2} />
                <rect x={158} y={35} width={4} height={4} rx={2} />
                <rect x={181} y={35} width={4} height={4} rx={2} />
                <rect x={203} y={35} width={4} height={4} rx={2} />
                <rect y={53} width={4} height={4} rx={2} />
                <rect x={23} y={53} width={4} height={4} rx={2} />
                <rect x={45} y={53} width={4} height={4} rx={2} />
                <rect x={68} y={53} width={4} height={4} rx={2} />
                <rect x={90} y={53} width={4} height={4} rx={2} />
                <rect x={113} y={53} width={4} height={4} rx={2} />
                <rect x={135} y={53} width={4} height={4} rx={2} />
                <rect x={158} y={53} width={4} height={4} rx={2} />
                <rect x={181} y={53} width={4} height={4} rx={2} />
                <rect x={203} y={53} width={4} height={4} rx={2} />
                <rect y={70} width={4} height={4} rx={2} />
                <rect x={23} y={70} width={4} height={4} rx={2} />
                <rect x={45} y={70} width={4} height={4} rx={2} />
                <rect x={68} y={70} width={4} height={4} rx={2} />
                <rect x={90} y={70} width={4} height={4} rx={2} />
                <rect x={113} y={70} width={4} height={4} rx={2} />
                <rect x={135} y={70} width={4} height={4} rx={2} />
                <rect x={158} y={70} width={4} height={4} rx={2} />
                <rect x={181} y={70} width={4} height={4} rx={2} />
                <rect x={203} y={70} width={4} height={4} rx={2} />
                <rect y={88} width={4} height={4} rx={2} />
                <rect x={23} y={88} width={4} height={4} rx={2} />
                <rect x={45} y={88} width={4} height={4} rx={2} />
                <rect x={68} y={88} width={4} height={4} rx={2} />
                <rect x={90} y={88} width={4} height={4} rx={2} />
                <rect x={113} y={88} width={4} height={4} rx={2} />
                <rect x={135} y={88} width={4} height={4} rx={2} />
                <rect x={158} y={88} width={4} height={4} rx={2} />
                <rect x={181} y={88} width={4} height={4} rx={2} />
                <rect x={203} y={88} width={4} height={4} rx={2} />
                <rect y={105} width={4} height={4} rx={2} />
                <rect x={23} y={105} width={4} height={4} rx={2} />
                <rect x={45} y={105} width={4} height={4} rx={2} />
                <rect x={68} y={105} width={4} height={4} rx={2} />
                <rect x={90} y={105} width={4} height={4} rx={2} />
                <rect x={113} y={105} width={4} height={4} rx={2} />
                <rect x={135} y={105} width={4} height={4} rx={2} />
                <rect x={158} y={105} width={4} height={4} rx={2} />
                <rect x={181} y={105} width={4} height={4} rx={2} />
                <rect x={203} y={105} width={4} height={4} rx={2} />
                <rect y={123} width={4} height={4} rx={2} />
                <rect x={23} y={123} width={4} height={4} rx={2} />
                <rect x={45} y={123} width={4} height={4} rx={2} />
                <rect x={68} y={123} width={4} height={4} rx={2} />
                <rect x={90} y={123} width={4} height={4} rx={2} />
                <rect x={113} y={123} width={4} height={4} rx={2} />
                <rect x={135} y={123} width={4} height={4} rx={2} />
                <rect x={158} y={123} width={4} height={4} rx={2} />
                <rect x={181} y={123} width={4} height={4} rx={2} />
                <rect x={203} y={123} width={4} height={4} rx={2} />
                <rect y={140} width={4} height={4} rx={2} />
                <rect x={23} y={140} width={4} height={4} rx={2} />
                <rect x={45} y={140} width={4} height={4} rx={2} />
                <rect x={68} y={140} width={4} height={4} rx={2} />
                <rect x={90} y={140} width={4} height={4} rx={2} />
                <rect x={113} y={140} width={4} height={4} rx={2} />
                <rect x={135} y={140} width={4} height={4} rx={2} />
                <rect x={158} y={140} width={4} height={4} rx={2} />
                <rect x={181} y={140} width={4} height={4} rx={2} />
                <rect x={203} y={140} width={4} height={4} rx={2} />
              </g>
            </svg>
          </div>
        </div>
      </section>
    </div>
  )
}
export default Login
