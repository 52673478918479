import React from 'react'
import { Map } from '../../Components/Map'
import BaseLayout from '../../template/BaseLayout'

export function Maps() {
  return (
    <BaseLayout>
      <Map />
    </BaseLayout>
  )
}
