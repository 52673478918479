/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useApi } from '../../hooks/useApi'
import { User } from '../../types/User'
import { AuthContext } from './AuthContext'

export function AuthProvider({ children }: { children: JSX.Element }) {
  const [user, setUser] = useState<User | null>(null)
  console.log(user)
  const [loadingAuth, setLoading] = useState(false)
  const api = useApi()

  const setToken = (token: string) => {
    localStorage.setItem('authToken', token)
  }

  function loadStorage() {
    setLoading(true)
    const storageToken = localStorage.getItem('censoTK')
    const storageUser = localStorage.getItem('censoManager')
    if (storageToken && storageUser) {
      setToken(JSON.parse(storageToken))
      setUser(JSON.parse(storageUser))
      setLoading(false)
    }
    setLoading(false)
  }

  function localStorageSet(name: string, data: any) {
    localStorage.setItem(name, JSON.stringify(data))
  }

  const signin = async (cpf: string, password: string) => {
    setLoading(true)
    const data = await api.signin(cpf, password)
    if (data.userId && data.token) {
      setUser(data)
      setToken(data.token)
      localStorageSet('censoManager', data)
      localStorageSet('censoTK', data.token)
      window.location.replace('/')
      toast.dismiss()
      toast.success('Seja bem vindo!')
      setLoading(false)
    }
    toast.dismiss()
    toast.error(data.response.data.message)
    setLoading(false)
  }

  const signout = async () => {
    setUser(null)
    setToken('')
    localStorage.removeItem('censoManager')
    localStorage.removeItem('censoTK')
    window.location.replace('/login')
    toast.dismiss()
    toast.success('Até logo!')
  }

  useEffect(() => {
    loadStorage()
  }, [])

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <AuthContext.Provider value={{ user, signin, signout, loadingAuth }}>
      {children}
    </AuthContext.Provider>
  )
}
