import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { RequireAuth } from './contexts/Auth/RequireAuth'
import { HomePage } from './pages/Home'
import { Maps } from './pages/Maps'
import { Relatorios } from './pages/Relatorios'
import Login from './pages/Login'

function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <RequireAuth>
            <HomePage />
          </RequireAuth>
        }
      />
      <Route
        path="/mapa"
        element={
          <RequireAuth>
            <Maps />
          </RequireAuth>
        }
      />
      <Route
        path="/relatorios"
        element={
          <RequireAuth>
            <Relatorios />
          </RequireAuth>
        }
      />
      <Route path="/login" element={<Login />} />
    </Routes>
  )
}

export default App
