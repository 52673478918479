/* eslint-disable consistent-return */
import React, { useContext, useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { AuthContext } from './AuthContext'

export function RequireAuth({ children }: { children: JSX.Element }) {
  const { user } = useContext(AuthContext)
  const [loadingAuth, setLoading] = useState(true)

  useEffect(() => {
    setLoading(false)
  }, [])

  if (!user && !loadingAuth) {
    return <Navigate to="/login" replace />
  }

  return children
}
