import React from 'react'
// import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import TitleComponent from '../../Utils/TitleComponent'

// function preventDefault(event: React.MouseEvent) {
//   event.preventDefault()
// }

interface ITotalRegister {
  title: string
  total: string
  // eslint-disable-next-line react/require-default-props
  isDataModified?: boolean
}

export default function TotalRegister({
  title,
  total,
  isDataModified,
}: ITotalRegister) {
  return (
    <>
      <TitleComponent>{title || 'Title is not found'}</TitleComponent>
      <Typography component="p" variant="h5">
        {total || 'Total is not found'} Famílias
      </Typography>
      {isDataModified ? (
        <Typography color="text.secondary" sx={{ flex: 1 }}>
          10 Janeiro, 2023
        </Typography>
      ) : null}
      {/* <div>
        <Link color="primary" href="#" onClick={preventDefault}>
          View balance
        </Link>
      </div> */}
    </>
  )
}
