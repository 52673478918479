import React, { useEffect } from 'react'
import { Container, Grid, Paper } from '@mui/material'
import useLoadingCenso from '../../../hooks/useLoadingCenso'
import TitleComponent from '../../Utils/TitleComponent'
import { Card } from './Content/Card'
import { ChartLine } from './Content/ChartLine'
import TableRegister from './Content/TableRegister'
import TotalRegister from './Content/TotalRegister'

export function RelatorioGeral() {
  const { renderLoading, setLoadingCenso } = useLoadingCenso()

  useEffect(() => {
    setTimeout(() => {
      setLoadingCenso(false)
    }, 200)
  }, [])

  return (
    <>
      {renderLoading}
      <div className="flex flex-col w-full items-center justify-center gap-7">
        <div className="flex gap-7 w-full max-w-screen-lg justify-between">
          <div className="w-full max-w-3xl">
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <ChartLine />
            </Paper>
          </div>
          <div className="flex flex-col gap-7 justify-center">
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                height: 180,
              }}
            >
              <TotalRegister
                isDataModified
                title="Total Cadastrado"
                total="442"
              />
            </Paper>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                height: 180,
              }}
            >
              <TotalRegister title="Menos de 1 salário" total="195" />
            </Paper>
          </div>
        </div>
        <div className="w-full max-w-screen-lg">
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <TableRegister />
          </Paper>
        </div>
        <div className="flex flex-col w-full max-w-screen-lg gap-10">
          {/* etapa 1 */}
          <div className="flex flex-col w-full max-w-screen-lg">
            <TitleComponent>
              Etapa 1 - 8 Dados Cadastrais do chefe familiar
            </TitleComponent>

            <div className="w-full max-w-screen-lg flex flex-wrap gap-7 px-3">
              <Card question="Pergunta aqui!" total="32" />
              <Card question="Pergunta aqui!" total="32" />
              <Card question="Pergunta aqui!" total="32" />
              <Card question="Pergunta aqui!" total="32" />
            </div>
          </div>
          {/* etapa 2 */}
          <div className="flex flex-col w-full max-w-screen-lg">
            <TitleComponent>Etapa 2 - 8 Dados da família</TitleComponent>
            <div className="w-full max-w-screen-lg flex flex-wrap gap-7 px-3">
              <Card
                question="Menos de 1 salário em casa de baixa renda testa o lenght"
                total="12"
              />
              <Card question="Pergunta aqui!" total="32" />
              <Card question="Pergunta aqui!" total="32" />
            </div>
          </div>
          {/* etapa 3 */}
          <div className="flex flex-col w-full max-w-screen-lg">
            <TitleComponent>
              Etapa 3 - 8 Edificações e infra-estrutura física da unidade
            </TitleComponent>
            <div className="w-full max-w-screen-lg flex flex-wrap gap-7 px-3">
              <Card question="Pergunta aqui!" total="32" />
              <Card question="Pergunta aqui!" total="32" />
            </div>
          </div>
          {/* etapa 4 */}
          <div className="flex flex-col w-full max-w-screen-lg">
            <TitleComponent>Etapa 4 - 8 Insegurança alimentar</TitleComponent>
            <div className="w-full max-w-screen-lg flex flex-wrap gap-7 px-3">
              <Card question="Pergunta aqui!" total="32" />
              <Card question="Pergunta aqui!" total="32" />
            </div>
          </div>
          {/* etapa 5 */}
          <div className="flex flex-col w-full max-w-screen-lg">
            <TitleComponent>Etapa 5 - 8 Agricultura</TitleComponent>
            <div className="w-full max-w-screen-lg flex flex-wrap gap-7 px-3">
              <Card question="Pergunta aqui!" total="32" />
              <Card question="Pergunta aqui!" total="32" />
            </div>
          </div>
          {/* etapa 6 */}
          <div className="flex flex-col w-full max-w-screen-lg">
            <TitleComponent>
              Etapa 6 - 8 Qualificação profissional
            </TitleComponent>
            <div className="w-full max-w-screen-lg flex flex-wrap gap-7 px-3">
              <Card question="Pergunta aqui!" total="32" />
              <Card question="Pergunta aqui!" total="32" />
            </div>
          </div>
          {/* etapa 7 */}
          <div className="flex flex-col w-full max-w-screen-lg">
            <TitleComponent>Etapa 7 - 8 Observaçoes finais</TitleComponent>
            <div className="w-full max-w-screen-lg flex flex-wrap gap-7 px-3">
              <Card question="Pergunta aqui!" total="32" />
              <Card question="Pergunta aqui!" total="32" />
            </div>
          </div>
          {/* etapa 8 */}
          <div className="flex flex-col w-full max-w-screen-lg">
            <TitleComponent>
              Etapa 8 - 8 Aspectos da convivência familiar e comunitária
            </TitleComponent>
            <div className="w-full max-w-screen-lg flex flex-wrap gap-7 px-3">
              <Card question="Pergunta aqui!" total="32" />
              <Card question="Pergunta aqui!" total="32" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
