import React from 'react'
import BaseLayout from '../../template/BaseLayout'
import { Home } from '../../Components/Home'

export function HomePage() {
  return (
    <BaseLayout>
      <Home />
    </BaseLayout>
  )
}
