/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable func-names */
import axios from 'axios'

const tk = localStorage.getItem('censoTK') || 'Not found'
const tokenCenso = tk !== 'Not found' ? JSON.parse(tk) : null

const api = axios.create({
  baseURL: 'https://api.censo.molde.dev/api/',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer${tokenCenso}`,
  },
})

api.interceptors.request.use(function (config) {
  config.headers!.Authorization = `Bearer ${tokenCenso}`
  return config
})

api.interceptors.response.use(
  (response) => {
    return response
  },
  async function (error) {
    if (error.response.status === 401 && tokenCenso) {
      localStorage.removeItem('censoTK')
      localStorage.removeItem('censoManager')
      window.location.replace('/login')
    }
    return Promise.reject(error)
  },
)

export default api
