import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'

import { BrowserRouter } from 'react-router-dom'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import brLocale from 'date-fns/locale/pt-BR'
import { StyledEngineProvider } from '@mui/styled-engine-sc'
import { ToastContainer } from 'react-toastify'
import { AuthProvider } from './contexts/Auth/AuthProvider'
import App from './App'
import 'react-toastify/dist/ReactToastify.css'

const localeMap = {
  br: brLocale,
}
const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <LocalizationProvider
        adapterLocale={localeMap.br}
        dateAdapter={AdapterDateFns}
      >
        <AuthProvider>
          <BrowserRouter>
            <App />
            <ToastContainer />
          </BrowserRouter>
        </AuthProvider>
      </LocalizationProvider>
    </StyledEngineProvider>
  </React.StrictMode>,
)
