/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import {
  useForm,
  SubmitHandler,
  useFieldArray,
  Controller,
} from 'react-hook-form'
import { format } from 'date-fns'
import { FormSteps, initialValuesState } from './types'
import style from './style.module.css'

interface IPropsInfosPeople {
  data: any
}

export function InfosPeople({ data }: IPropsInfosPeople) {
  const { register, handleSubmit, reset, control } = useForm<FormSteps>({
    defaultValues: initialValuesState,
  })
  const { fields, append } = useFieldArray<FormSteps, 'family', 'familyId'>({
    control,
    name: 'family',
    keyName: 'familyId',
  })
  console.log(data)

  const [cisternaTrue, setCisternaTrue] = useState(false)

  const isExist = (value: string) => {
    if (!value) {
      return 'Não informado'
    }
    if (value === 'Selecione') {
      return 'Não informado'
    }
    return value
  }

  const transformArray = (array: string[]) => {
    if (!array) return 'Não informado'
    if (array.length === 0) return 'Não informado'
    let result = ''
    for (let i = 0; i < array.length; i++) {
      if (i === array.length - 1) {
        result += array[i]
      } else {
        result += `${array[i]}, `
      }
    }
    return result
  }

  const returnYesOrNo = (value: boolean) => {
    if (value) {
      return 'Sim'
    }
    return 'Não'
  }

  const returnString = (value: string) => {
    if (!value) return 'Não informado'
    if (value === 'Selecione') return 'Não informado'
    return value
  }

  // eslint-disable-next-line consistent-return
  const cisterna = (value: any) => {
    if (!value) return 'Não informado'
    if (value.length === 0) return 'Não informado'
    if (!value.familias) return 'Não informado'
    if (value.familias.length === 0) return 'Não informado'
    const serveMaisDeUma = value.familias.filter(
      (item: any) => item === 'Serve a uma ou mais famílias',
    )
    if (serveMaisDeUma.length) {
      const qntsFamilias = !value.quantasFamilias ? 0 : value.quantasFamilias
      const qntsPessoas = !value.quantasPessoas ? 0 : value.quantasPessoas
      const result = `${serveMaisDeUma[0]} - ${qntsFamilias} famílias - ${qntsPessoas} pessoas compõem essas famílias`
      return result
    }
    return value.familias[0]
  }

  const carne = (value: any) => {
    if (!value) return 'Não informado'
    if (value.length === 0) return 'Não informado'
    if (!value.come) return 'Não'

    if (value.vezes === 1) {
      return `Come ${value.vezes} vez por dia`
    }
    return `Come ${value.vezes} vezes por dia`
  }

  useEffect(() => {
    const programasSociais = data.familia.programasSociais
      ? data.familia.programasSociais
          .map(
            (item: any) =>
              item.programa &&
              `${item.programa} R$:${item.valor ? item.valor : '0'}`,
          )
          .filter((item: any) => item !== null)
      : ['Não informado']
    const carroPipa = data.infra.fonteAbastecimentoAgua.filter(
      (item: any) => item === 'Carro Pipa',
    )
    setCisternaTrue(false)
    setCisternaTrue(carroPipa.length > 0)
    reset({
      // step 1
      nomeCompleto: data.cadastro?.nomeCompleto,
      apelido: data.cadastro?.apelido || 'Não informado',
      bairro: data.cadastro?.bairro || 'Não informado',
      cartaoSus: data.cadastro?.cartaoSus || 'Não informado',
      complemento: data.cadastro?.complemento || 'Não informado',
      cpf: data.cadastro?.cpf || 'Não informado',
      dataNascimento: data.cadastro?.dataNascimento
        ? format(new Date(data.cadastro?.dataNascimento), 'dd/MM/yyyy')
        : 'Não informado',
      email: data.cadastro?.email || 'Não informado',
      endereco: data.cadastro?.endereco || 'Não informado',
      grauEscolaridade: data.cadastro?.grauEscolaridade || 'Não informado',
      imovelRuralUrbano: data.cadastro?.imovelRuralUrbano || 'Não informado',
      nis: data.cadastro?.nis || 'Não informado',
      nomeMae: data.cadastro?.nomeMae || 'Não informado',
      nomePai: data.cadastro?.nomePai || 'Não informado',
      numero: data.cadastro?.numero || 'Não informado',
      orgaoExpedidor: data.cadastro?.orgaoExpedidor || 'Não informado',
      rg: data.cadastro?.rg || 'Não informado',
      pontoReferencia: data.cadastro?.pontoReferencia || 'Não informado',
      situacaoImovel: data.cadastro?.situacaoImovel || 'Não informado',
      telefone: returnString(data.cadastro?.telefone),
      ubs: returnString(data.cadastro?.ubs),
      // step 2
      quantosMoraCasa: data.familia?.numResidentes
        ? data.familia?.numResidentes.toString()
        : 'Não informado',
      rendaMensal: returnString(data.familia?.rendaMensal),
      deficiencia: data.familia?.deficiencia?.nomeCompleto
        ? `${data.familia?.deficiencia?.nomeCompleto}, ${data.familia?.deficiencia?.parentesco}, ${data.familia?.deficiencia?.tipoDeficiencia}`
        : 'Não informado',
      recebeProgramasSociais: transformArray(programasSociais),
      possuiTransporte: transformArray(data.familia.transporte.possui),
      pertenceGrupoTradicional: returnString(
        data.familia?.comunidadeTradicional?.nome,
      ),
      familiasImovel: transformArray(data.infra.multiplasFamilias.nomeCompleto),
      imovelFamiliaStep3: transformArray(data.infra.multiplosImoveis.endereco),
      casaConstruidaStep3: transformArray(data.infra.casaConstruidaComo),
      materialParedeStep3: transformArray(data.infra.materialPredominante),
      numeroComodosImovelStep3: returnString(data.infra.totalComodos),
      estadoConservacaoStep3: returnString(data.infra.estadoConservacaoCasa),
      dormirSalaStep3: returnYesOrNo(data.infra.faltaQuartos),
      possuiEnergiaStep3: transformArray(data.infra.energia.fonte),
      precisaReformaStep3: transformArray(data.infra.situacoesIndesejadas),
      areaSujeitaStep3: transformArray(data.infra.areaSujeitaInundacao),
      existeCalcamentoStep3: returnYesOrNo(data.infra.existeCalcamento),
      possuiAcessibiStep3: returnYesOrNo(data.infra.possuiAcessibilidade),
      possuiInternetStep3: transformArray(data.infra.possuiInternet.tipo),
      possuiBanheiroStep3: returnString(data.infra.banheiro.quantos),
      banheiroForaStep3: transformArray(data.infra.banheiro.dentroOuFora),
      escoamentoStep3: returnString(data.infra.escoamento),
      esgotoStep3: returnString(data.infra.destinoEsgoto),
      domesticoLixoStep3: returnString(data.infra.destinoLixo),
      abastecimentoStep3: transformArray(data.infra.fonteAbastecimentoAgua),
      recebeAguaPipaStep3: returnYesOrNo(data.infra.recebeuAguaDaPrefeitura),
      precisaMotorPipaStep3: returnYesOrNo(data.infra.precisaMotorParaAgua),
      capacidadePipaStep3: returnString(data.infra.litrosDaCisterna),
      cisternaRecursosStep3: returnString(data.infra.cisternaRecursosProprios),
      cisternaRachadurasStep3: returnYesOrNo(data.infra.cisternaComRachaduras),
      cisternaNumeracaoStep3: returnString(data.infra.numeracaoCisterna),
      captacaoAguaStep3: returnYesOrNo(data.infra.cisternaAguaDaChuva),
      cisternaFamiliaStep3: cisterna(data.infra.cisternaServe),
      qntsVezesComemDiaStep4: returnString(data.alimentacao.refeicoesDia),
      compraAlimentosStep4: transformArray(data.alimentacao.fonteAlimentos),
      preocupaAcabarComidaStep4: returnYesOrNo(
        data.alimentacao.preocupacaoComidaAcabasse,
      ),
      comidaAcabouAntesStep4: returnYesOrNo(
        data.alimentacao.comidaAcabouSemDinheiro,
      ),
      diminuiuQntAlimentoStep4: returnYesOrNo(data.alimentacao.adultoDiminuiu),
      sentiuFomeNaoSuficienteStep4: returnYesOrNo(data.alimentacao.sentiuFome),
      perdeuPesoComidaSuficiStep4: returnYesOrNo(data.alimentacao.perdeuPeso),
      diaIntSemComerStep4: returnYesOrNo(data.alimentacao.diaSemComer),
      comeuMenosNaoDinheiroStep4: returnYesOrNo(data.alimentacao.comeuMenos),
      poucosAlimenDinheiroAcabouStep4: returnYesOrNo(
        data.alimentacao.poucosAlimentos,
      ),
      alimenSaudavelSemDinheiroStep4: returnYesOrNo(
        data.alimentacao.menosSaudavel,
      ),
      qualidadeAliemntacaoStep4: returnString(
        data.alimentacao.qualidadeAlimentacao,
      ),
      passouFomeUltim10anosStep4: returnYesOrNo(
        data.alimentacao.qualidadeAlimentacao,
      ),
      cafeDaManhaStep4: transformArray(data.alimentacao.cafe),
      almocoStep4: transformArray(data.alimentacao.almoco),
      jantarStep4: transformArray(data.alimentacao.jantar),
      deixouDeComerGasAcabouStep4: returnYesOrNo(data.alimentacao.gas),
      alimentoCozidoEmStep4: returnString(data.alimentacao.cozidoEm),
      consumirFrutasStep4: data.alimentacao.frutasVerduras.come
        ? transformArray(data.alimentacao.frutasVerduras.quais)
        : 'Não',
      comeProteinaStep4: carne(data.alimentacao.proteinaAnimal),
      animaisTiposStep5: transformArray(data.agricultura.animais.tipos),
      animaisProdutosStep5: transformArray(data.agricultura.animais.produtos),
      terrenoCaracterizacaoStep5: transformArray(
        data.agricultura.terreno.caracterizacao,
      ),
      terrenoMetragemStep5: returnString(data.agricultura.terreno.metragem),
      plantacaoTiposStep5: transformArray(data.agricultura.plantacao.tipos),
      plantacaoDocsStep5: transformArray(
        data.agricultura.plantacao.documentacao,
      ),
      sementeStep5: returnYesOrNo(data.agricultura.programaSementes),
      cursoQualStep6: returnString(data.profissional.curso.qual),
      cursoAreaStep6: transformArray(data.profissional.curso.area),
      protecaoSocialStep7: transformArray(data.observacoes.protecaoSocial),
      qntsAnosMoraEstadoStep8: data.aspectoFamiliar.anosMoraNoEstado,
      sempreMorouEstadoStep8: returnYesOrNo(data.aspectoFamiliar.sempreEstado),
      qntsAnosMoraMunicipioStep8: data.aspectoFamiliar.anosMoraNoMunicipio,
      sempreMorouMunicipioStep8: returnYesOrNo(
        data.aspectoFamiliar.sempreMunicipio,
      ),
      qntsAnosMoraBairroStep8: data.aspectoFamiliar.anosMoraNoBairro,
      sempreMorouBairroStep8: returnYesOrNo(
        data.aspectoFamiliar.familiaMesmoBairro,
      ),
      parentesProximosMoradiaStep8: returnYesOrNo(
        data.aspectoFamiliar.parentesPorximos,
      ),
      vizinhosRedeApoioStep8: returnYesOrNo(data.aspectoFamiliar.vizinhosApoio),
      gruposReligiososStep8: returnYesOrNo(
        data.aspectoFamiliar.familiaGrupoReligioso,
      ),
      movimentosSociaisStep8: returnYesOrNo(
        data.aspectoFamiliar.familiaMovimentosSociais,
      ),
      familiaCadastrCRAsStep8: returnYesOrNo(data.aspectoFamiliar.familiaCras),
      familiaCadastrCREAsStep8: returnYesOrNo(
        data.aspectoFamiliar.familiaCreas,
      ),
      familiaProgramasStep8: transformArray(
        data.aspectoFamiliar.familiaServicos,
      ),
    })

    for (let i = 0; i < data.familia.familiares.length; i++) {
      append({
        nomeCompletoFamiliar: isExist(data.familia.familiares[i].nomeCompleto),
        cpfFamiliar: isExist(data.familia.familiares[i].cpf),
        nomeDaMaeFamiliar: isExist(data.familia.familiares[i].nomeDaMae),
        dataNascimentoFamiliar: isExist(
          format(
            new Date(data.familia.familiares[i].dataNascimento),
            'dd/MM/yyyy',
          ),
        ),
        grauEscolaridadeFamiliar: isExist(
          data.familia.familiares[i].escolaridade,
        ),
        parentescoFamiliar: isExist(
          data.familia.familiares[i].relacaoParentesco,
        ),
        generoFamiliar: isExist(data.familia.familiares[i].genero),
        identidadeSexualFamiliar: isExist(
          data.familia.familiares[i].identidadeSexual,
        ),
        trabalhoFamiliar: isExist(data.familia.familiares[i].ocupacao),
      })
    }
  }, [data])

  const onSubmit: SubmitHandler<FormSteps> = (valueForm) => {
    console.log(valueForm)
  }

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <div className="flex justify-end">
        <Button variant="outlined" size="small" onClick={handleClickOpen}>
          Ver mais
        </Button>
      </div>
      <Dialog
        fullWidth
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <div
            style={{
              display: 'flex',
              gap: '1rem',
              justifyContent: 'space-between',
            }}
          >
            <span>
              {data.cadastro.nomeCompleto
                ? data.cadastro.nomeCompleto
                : 'Nome não encontrado'}{' '}
              -{' '}
              {data.cadastro.endereco
                ? data.cadastro.endereco
                : 'Endereço não encontrado'}
            </span>
            <span style={{ fontSize: '16px' }}>
              Cadastrado por: {data.whoCreated}
            </span>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    Etapa 1 - 8 Dados Cadastrais do chefe familiar
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '2rem',
                  }}
                >
                  <div style={{ display: 'flex', gap: '4rem' }}>
                    <TextField
                      label="Nome Completo"
                      fullWidth
                      variant="standard"
                      {...register('nomeCompleto')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label="Data de Nascimento"
                      fullWidth
                      variant="standard"
                      {...register('dataNascimento')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label="Nome da Mãe"
                      fullWidth
                      variant="standard"
                      {...register('nomeMae')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label="Nome do Pai"
                      fullWidth
                      variant="standard"
                      {...register('nomePai')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex', gap: '4rem' }}>
                    <TextField
                      label="Apelido"
                      fullWidth
                      variant="standard"
                      {...register('apelido')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label="CPF"
                      fullWidth
                      variant="standard"
                      {...register('cpf')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label="RG"
                      fullWidth
                      variant="standard"
                      {...register('rg')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label="Órgão Expedidor"
                      fullWidth
                      variant="standard"
                      {...register('orgaoExpedidor')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex', gap: '4rem' }}>
                    <TextField
                      label="NIS"
                      fullWidth
                      variant="standard"
                      {...register('nis')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label="Cartão do SUS"
                      fullWidth
                      variant="standard"
                      {...register('cartaoSus')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label="UBS atendido"
                      fullWidth
                      variant="standard"
                      {...register('ubs')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label="E-mail"
                      fullWidth
                      variant="standard"
                      {...register('email')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex', gap: '4rem' }}>
                    <TextField
                      label="Telefone (Whatsapp)"
                      fullWidth
                      variant="standard"
                      {...register('telefone')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label="Grau de escolaridade"
                      fullWidth
                      variant="standard"
                      {...register('grauEscolaridade')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label="Endereço"
                      fullWidth
                      variant="standard"
                      {...register('endereco')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label="Número"
                      fullWidth
                      variant="standard"
                      {...register('numero')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex', gap: '4rem' }}>
                    <TextField
                      label="Complemento"
                      fullWidth
                      variant="standard"
                      {...register('complemento')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label="Bairro"
                      fullWidth
                      variant="standard"
                      {...register('bairro')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label="Ponto de Referência"
                      fullWidth
                      variant="standard"
                      {...register('pontoReferencia')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label="Situação do imóvel"
                      fullWidth
                      variant="standard"
                      {...register('situacaoImovel')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex', gap: '4rem' }}>
                    <TextField
                      label="Imóvel rural ou urbano?"
                      variant="standard"
                      {...register('imovelRuralUrbano')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Etapa 2 - 8 Dados da família</Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '2rem',
                  }}
                >
                  <div style={{ display: 'flex', gap: '4rem' }}>
                    <TextField
                      label="Quantos moram nesta casa?"
                      fullWidth
                      variant="standard"
                      {...register('quantosMoraCasa')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label="Renda familiar mensal"
                      fullWidth
                      variant="standard"
                      {...register('rendaMensal')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label="Transporte"
                      fullWidth
                      variant="standard"
                      {...register('possuiTransporte')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label="Grupo Tradicional"
                      fullWidth
                      variant="standard"
                      {...register('pertenceGrupoTradicional')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex', gap: '4rem' }}>
                    <TextField
                      label="Familiar com deficiência"
                      fullWidth
                      variant="standard"
                      multiline
                      rows={2}
                      {...register('deficiencia')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label="Programas sociais"
                      fullWidth
                      variant="standard"
                      multiline
                      rows={2}
                      {...register('recebeProgramasSociais')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>

                  {fields.map((dataField, index) => {
                    return (
                      <div key={dataField.familyId} className={style.container}>
                        <span style={{ marginBottom: '-20px' }}>
                          Membro da família
                        </span>
                        <div className={style.content}>
                          <Controller
                            name={`family.${index}.nomeCompletoFamiliar`}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                label="Nome completo"
                                variant="standard"
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            )}
                          />
                          <Controller
                            name={`family.${index}.cpfFamiliar`}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                label="CPF"
                                variant="standard"
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            )}
                          />
                          <Controller
                            name={`family.${index}.nomeDaMaeFamiliar`}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                label="Nome da mãe"
                                variant="standard"
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            )}
                          />
                        </div>
                        <div className={style.content}>
                          <Controller
                            name={`family.${index}.dataNascimentoFamiliar`}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                label="Data de nascimento"
                                variant="standard"
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            )}
                          />
                          <Controller
                            name={`family.${index}.grauEscolaridadeFamiliar`}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                label="Escolaridade"
                                variant="standard"
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            )}
                          />
                          <Controller
                            name={`family.${index}.parentescoFamiliar`}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                label="Parentesco"
                                variant="standard"
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            )}
                          />
                        </div>
                        <div className={style.content}>
                          <Controller
                            name={`family.${index}.generoFamiliar`}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                label="Gênero"
                                variant="standard"
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            )}
                          />
                          <Controller
                            name={`family.${index}.identidadeSexualFamiliar`}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                label="Identidade sexual"
                                variant="standard"
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            )}
                          />
                          <Controller
                            name={`family.${index}.trabalhoFamiliar`}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                label="Trabalho"
                                variant="standard"
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                    )
                  })}
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography>
                    Etapa 3 - 8 Edificações e infra-estrutura física da unidade
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '2rem',
                  }}
                >
                  <div style={{ display: 'flex', gap: '4rem' }}>
                    <TextField
                      label="Uma ou mais famílias morando neste imóvel?"
                      fullWidth
                      variant="standard"
                      multiline
                      rows={2}
                      {...register('familiasImovel')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label="A família possui mais algum outro imóvel?"
                      fullWidth
                      variant="standard"
                      multiline
                      rows={2}
                      {...register('imovelFamiliaStep3')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex', gap: '4rem' }}>
                    <TextField
                      label="A casa foi construída como?"
                      fullWidth
                      variant="standard"
                      multiline
                      rows={2}
                      {...register('casaConstruidaStep3')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label="Qual é o material na construção das paredes?"
                      fullWidth
                      variant="standard"
                      multiline
                      rows={2}
                      {...register('materialParedeStep3')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex', gap: '4rem' }}>
                    <TextField
                      label="Nº de cômodos no imóvel?"
                      fullWidth
                      variant="standard"
                      {...register('numeroComodosImovelStep3')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label="Estado de conservação da casa?"
                      fullWidth
                      variant="standard"
                      {...register('estadoConservacaoStep3')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex', gap: '4rem' }}>
                    <TextField
                      label="Há pessoas da família que precisam dormir na sala ou cozinha por falta de quartos?"
                      fullWidth
                      variant="standard"
                      {...register('dormirSalaStep3')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label="A casa possui Energia Elétrica?"
                      fullWidth
                      variant="standard"
                      multiline
                      rows={1}
                      {...register('possuiEnergiaStep3')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex', gap: '4rem' }}>
                    <TextField
                      label="A casa precisa passar por reforma?"
                      fullWidth
                      variant="standard"
                      multiline
                      rows={2}
                      {...register('precisaReformaStep3')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label="Área onde a casa encontra-se está sujeita..."
                      fullWidth
                      variant="standard"
                      multiline
                      rows={2}
                      {...register('areaSujeitaStep3')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex', gap: '4rem' }}>
                    <TextField
                      label="A casa possui banheiro? Quantos?"
                      fullWidth
                      variant="standard"
                      {...register('possuiBanheiroStep3')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label="Banheiro dentro ou fora de casa?"
                      fullWidth
                      variant="standard"
                      {...register('banheiroForaStep3')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex', gap: '4rem' }}>
                    <TextField
                      label="Existe calçamento/pavimentação?"
                      fullWidth
                      variant="standard"
                      {...register('existeCalcamentoStep3')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label="Possui acessibilidade?"
                      fullWidth
                      variant="standard"
                      {...register('possuiAcessibiStep3')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label="Possui internet na residência?"
                      fullWidth
                      variant="standard"
                      {...register('possuiInternetStep3')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex', gap: '4rem' }}>
                    <TextField
                      label="Como é feito o escoamento do sanitário?"
                      fullWidth
                      variant="standard"
                      {...register('escoamentoStep3')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label="Qual o destino do esgoto da casa?"
                      fullWidth
                      variant="standard"
                      {...register('esgotoStep3')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label="Qual o destino do lixo doméstico?"
                      fullWidth
                      variant="standard"
                      {...register('domesticoLixoStep3')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex', gap: '4rem' }}>
                    <TextField
                      label="Qual a fonte de abastecimento de água do imóvel?"
                      fullWidth
                      variant="standard"
                      multiline
                      rows={1}
                      {...register('abastecimentoStep3')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                  {cisternaTrue ? (
                    <div className={style.container}>
                      <div className={style.content}>
                        <TextField
                          label="Já recebeu água em carro pipa pelo programa da prefeitura?"
                          fullWidth
                          variant="standard"
                          {...register('recebeAguaPipaStep3')}
                          InputProps={{
                            readOnly: true,
                            title:
                              'Já recebeu água em carro pipa pelo programa da prefeitura?',
                          }}
                        />
                        <TextField
                          label="O caminhão pipa precisa de motor para descarregar a água em sua cisterna?"
                          fullWidth
                          variant="standard"
                          {...register('precisaMotorPipaStep3')}
                          InputProps={{
                            readOnly: true,
                            title:
                              'O caminhão pipa precisa de motor para descarregar a água em sua cisterna?',
                          }}
                        />
                        <TextField
                          label="Qual a capacidade de litros da sua cisterna?"
                          fullWidth
                          variant="standard"
                          {...register('capacidadePipaStep3')}
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </div>
                      <div className={style.content}>
                        <TextField
                          label="Sua cisterna foi construída com recursos próprios ou por programa do governo federal?"
                          fullWidth
                          variant="standard"
                          {...register('cisternaRecursosStep3')}
                          InputProps={{
                            readOnly: true,
                            title:
                              'Sua cisterna foi construída com recursos próprios ou por programa do governo federal?',
                          }}
                        />
                        <TextField
                          label="Sua cisterna possui rachaduras ou vazamentos?"
                          fullWidth
                          variant="standard"
                          {...register('cisternaRachadurasStep3')}
                          InputProps={{
                            readOnly: true,
                            title:
                              'Sua cisterna possui rachaduras ou vazamentos?',
                          }}
                        />
                        <TextField
                          label="Sua cisterna tem numeração? Qual?"
                          fullWidth
                          variant="standard"
                          {...register('cisternaNumeracaoStep3')}
                          InputProps={{
                            readOnly: true,
                            title: 'Sua cisterna tem numeração? Qual?',
                          }}
                        />
                      </div>
                      <div className={style.content}>
                        <TextField
                          label="A cisterna possui sistema de captação de água da chuva?"
                          fullWidth
                          variant="standard"
                          {...register('captacaoAguaStep3')}
                          InputProps={{
                            readOnly: true,
                            title:
                              'A cisterna possui sistema de captação de água da chuva?',
                          }}
                          style={{ maxWidth: '350px' }}
                        />
                        <TextField
                          label="A cisterna serve somente a família do proprietário do imóvel ou mais alguma outra família?"
                          fullWidth
                          variant="standard"
                          {...register('cisternaFamiliaStep3')}
                          InputProps={{
                            readOnly: true,
                            title:
                              'A cisterna serve somente a família do proprietário do imóvel ou mais alguma outra família?',
                          }}
                        />
                      </div>
                    </div>
                  ) : null}
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4a-content"
                  id="panel4a-header"
                >
                  <Typography>Etapa 4 - 8 Insegurança alimentar</Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '2rem',
                  }}
                >
                  <div style={{ display: 'flex', gap: '4rem' }}>
                    <TextField
                      label="Quantas vezes vocês comem no dia?"
                      fullWidth
                      variant="standard"
                      {...register('qntsVezesComemDiaStep4')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label="Como vocês adiquerem os alimentos?"
                      fullWidth
                      variant="standard"
                      multiline
                      rows={1}
                      {...register('compraAlimentosStep4')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label="Você teve a preocupação de que a comida na sua casa acabasse antes que tivesse condição de comprar mais comida?"
                      fullWidth
                      variant="standard"
                      {...register('preocupaAcabarComidaStep4')}
                      InputProps={{
                        readOnly: true,
                        title:
                          'Você teve a preocupação de que a comida na sua casa acabasse antes que tivesse condição de comprar mais comida?',
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex', gap: '4rem' }}>
                    <TextField
                      label="A comida acabou antes que você tivesse dinheiro para comprar mais?"
                      fullWidth
                      variant="standard"
                      {...register('comidaAcabouAntesStep4')}
                      InputProps={{
                        readOnly: true,
                        title:
                          'A comida acabou antes que você tivesse dinheiro para comprar mais?',
                      }}
                    />
                    <TextField
                      label="Você ou algum adulto em sua casa diminuiu, alguma vez, a quantidade de alimentos nas refeições, ou pulou refeições, porque não havia dinheiro suficiente para comprar a comida?"
                      fullWidth
                      variant="standard"
                      {...register('diminuiuQntAlimentoStep4')}
                      InputProps={{
                        readOnly: true,
                        title:
                          'Você ou algum adulto em sua casa diminuiu, alguma vez, a quantidade de alimentos nas refeições, ou pulou refeições, porque não havia dinheiro suficiente para comprar a comida?',
                      }}
                    />
                    <TextField
                      label="Você alguma vez sentiu fome, mas não comeu porque não podia comprar comida suficiente?"
                      fullWidth
                      variant="standard"
                      {...register('sentiuFomeNaoSuficienteStep4')}
                      InputProps={{
                        readOnly: true,
                        title:
                          'Você alguma vez sentiu fome, mas não comeu porque não podia comprar comida suficiente?',
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex', gap: '4rem' }}>
                    <TextField
                      label="Você perdeu peso porque não tinha dinheiro suficiente para comprar comida?"
                      fullWidth
                      variant="standard"
                      {...register('perdeuPesoComidaSuficiStep4')}
                      InputProps={{
                        readOnly: true,
                        title:
                          'Você perdeu peso porque não tinha dinheiro suficiente para comprar comida?',
                      }}
                    />
                    <TextField
                      label="Você ou qualquer outro adulto em sua casa ficou, alguma vez, um dia inteiro sem comer, ou teve apenas uma refeição ao dia, porque não havia dinheiro para comprar a comida?"
                      fullWidth
                      variant="standard"
                      {...register('diaIntSemComerStep4')}
                      InputProps={{
                        readOnly: true,
                        title:
                          'Você ou qualquer outro adulto em sua casa ficou, alguma vez, um dia inteiro sem comer, ou teve apenas uma refeição ao dia, porque não havia dinheiro para comprar a comida?',
                      }}
                    />
                    <TextField
                      label="Você alguma vez comeu menos do que achou que devia porque não havia dinheiro o suficiente para comprar comida?"
                      fullWidth
                      variant="standard"
                      {...register('comeuMenosNaoDinheiroStep4')}
                      InputProps={{
                        readOnly: true,
                        title:
                          'Você alguma vez comeu menos do que achou que devia porque não havia dinheiro o suficiente para comprar comida?',
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex', gap: '4rem' }}>
                    <TextField
                      label="Você teve que se basear em apenas alguns poucos tipos de alimentos para alimentar os moradores com menos de 18 anos, porque o dinheiro acabou?"
                      fullWidth
                      variant="standard"
                      {...register('poucosAlimenDinheiroAcabouStep4')}
                      InputProps={{
                        readOnly: true,
                        title:
                          'Você teve que se basear em apenas alguns poucos tipos de alimentos para alimentar os moradores com menos de 18 anos, porque o dinheiro acabou?',
                      }}
                    />
                    <TextField
                      label="Você não pôde oferecer a algum morador com menos de 18 anos uma alimentação saudável e variada, porque não tinha dinheiro?"
                      fullWidth
                      variant="standard"
                      {...register('alimenSaudavelSemDinheiroStep4')}
                      InputProps={{
                        readOnly: true,
                        title:
                          'Você não pôde oferecer a algum morador com menos de 18 anos uma alimentação saudável e variada, porque não tinha dinheiro?',
                      }}
                    />
                    <TextField
                      label="Como você considera a qualidade da sua alimentação?"
                      fullWidth
                      variant="standard"
                      {...register('qualidadeAliemntacaoStep4')}
                      InputProps={{
                        readOnly: true,
                        title:
                          'Como você considera a qualidade da sua alimentação?',
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex', gap: '4rem' }}>
                    <TextField
                      label="Você ou alguém da sua família já chegou a passar fome nos últimos 10 anos?"
                      fullWidth
                      variant="standard"
                      {...register('passouFomeUltim10anosStep4')}
                      InputProps={{
                        readOnly: true,
                        title:
                          'Você ou alguém da sua família já chegou a passar fome nos últimos 10 anos?',
                      }}
                    />
                    <TextField
                      label="Sua família já deixou de comer porque o gás de cozinha acabou e não tinha dinheiro para comprar mais?"
                      fullWidth
                      variant="standard"
                      {...register('deixouDeComerGasAcabouStep4')}
                      InputProps={{
                        readOnly: true,
                        title:
                          'Sua família já deixou de comer porque o gás de cozinha acabou e não tinha dinheiro para comprar mais?',
                      }}
                    />
                    <TextField
                      label="O alimento é cozido em?"
                      fullWidth
                      variant="standard"
                      {...register('alimentoCozidoEmStep4')}
                      InputProps={{
                        readOnly: true,
                        title:
                          'Como você considera a qualidade da sua alimentação?',
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex', gap: '4rem' }}>
                    <TextField
                      label="Costumam consumir frutas e verduras diariamente?"
                      fullWidth
                      variant="standard"
                      {...register('consumirFrutasStep4')}
                      InputProps={{
                        readOnly: true,
                        title:
                          'Costumam consumir frutas e verduras diariamente?',
                      }}
                    />
                    <TextField
                      label="Sua família come proteína animal (carne bovina, suína, peixe, frango) ao menos uma vez ao dia?"
                      fullWidth
                      variant="standard"
                      {...register('comeProteinaStep4')}
                      InputProps={{
                        readOnly: true,
                        title:
                          'Sua família come proteína animal (carne bovina, suína, peixe, frango) ao menos uma vez ao dia?',
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex', gap: '4rem' }}>
                    <TextField
                      label="Café da manhã"
                      fullWidth
                      variant="standard"
                      multiline
                      rows={2}
                      {...register('cafeDaManhaStep4')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label="Almoço"
                      fullWidth
                      variant="standard"
                      multiline
                      rows={2}
                      {...register('almocoStep4')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label="Jantar"
                      fullWidth
                      variant="standard"
                      multiline
                      rows={2}
                      {...register('jantarStep4')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel5a-content"
                  id="panel5a-header"
                >
                  <Typography>Etapa 5 - 8 Agricultura</Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '2rem',
                  }}
                >
                  <div style={{ display: 'flex', gap: '4rem' }}>
                    <TextField
                      label="Possui criação de animais? tipos?"
                      fullWidth
                      multiline
                      rows={2}
                      variant="standard"
                      {...register('animaisTiposStep5')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label="Possui criação de animais? produtos?"
                      fullWidth
                      multiline
                      rows={2}
                      variant="standard"
                      {...register('animaisProdutosStep5')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex', gap: '4rem' }}>
                    <TextField
                      label="Possui terreno? caracterização"
                      fullWidth
                      multiline
                      rows={2}
                      variant="standard"
                      {...register('terrenoCaracterizacaoStep5')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label="Possui criação de animais? metragem"
                      fullWidth
                      multiline
                      rows={2}
                      variant="standard"
                      {...register('terrenoMetragemStep5')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex', gap: '4rem' }}>
                    <TextField
                      label="Realiza plantação? tipos"
                      fullWidth
                      multiline
                      rows={2}
                      variant="standard"
                      {...register('plantacaoTiposStep5')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label="Realiza plantação? documentacao"
                      fullWidth
                      multiline
                      rows={2}
                      variant="standard"
                      {...register('plantacaoDocsStep5')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex', gap: '4rem' }}>
                    <TextField
                      label="Participa de algum programa de sementes?"
                      fullWidth
                      multiline
                      rows={2}
                      variant="standard"
                      {...register('sementeStep5')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel6a-content"
                  id="panel6a-header"
                >
                  <Typography>Etapa 6 - 8 Qualificação profissional</Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '2rem',
                  }}
                >
                  <div style={{ display: 'flex', gap: '4rem' }}>
                    <TextField
                      label="Realizar algum curso de formação profissional? qual?"
                      fullWidth
                      multiline
                      rows={2}
                      variant="standard"
                      {...register('cursoQualStep6')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                    <TextField
                      label="Realizar algum curso de formação profissional? área?"
                      fullWidth
                      multiline
                      rows={2}
                      variant="standard"
                      {...register('cursoAreaStep6')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel7a-content"
                  id="panel7a-header"
                >
                  <Typography>Etapa 7 - 8 Observaçoes finais</Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '2rem',
                  }}
                >
                  <div style={{ display: 'flex', gap: '4rem' }}>
                    <TextField
                      label="Você ou algum membro da família já precisou acionar os órgãos de proteção social?"
                      fullWidth
                      multiline
                      rows={2}
                      variant="standard"
                      {...register('protecaoSocialStep7')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel8a-content"
                  id="panel8a-header"
                >
                  <Typography>
                    Etapa 8 - 8 Aspectos da convivência familiar e comunitária
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '2rem',
                  }}
                >
                  <div style={{ display: 'flex', gap: '4rem' }}>
                    <TextField
                      label="Há quantos anos a família mora neste estado:"
                      fullWidth
                      variant="standard"
                      {...register('qntsAnosMoraEstadoStep8')}
                      InputProps={{
                        readOnly: true,
                        title: 'Há quantos anos a família mora neste estado:',
                      }}
                    />
                    <TextField
                      label="A família sempre morou no estado?"
                      fullWidth
                      variant="standard"
                      {...register('sempreMorouEstadoStep8')}
                      InputProps={{
                        readOnly: true,
                        title: 'A família sempre morou no estado?',
                      }}
                    />
                    <TextField
                      label="Há quantos anos a família mora no município:"
                      fullWidth
                      variant="standard"
                      {...register('qntsAnosMoraMunicipioStep8')}
                      InputProps={{
                        readOnly: true,
                        title: 'Há quantos anos a família mora no município:',
                      }}
                    />
                    <TextField
                      label="A família sempre morou no município?"
                      fullWidth
                      variant="standard"
                      {...register('sempreMorouMunicipioStep8')}
                      InputProps={{
                        readOnly: true,
                        title: 'A família sempre morou no município?',
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex', gap: '4rem' }}>
                    <TextField
                      label="Há quantos anos a família mora no bairro atual:"
                      fullWidth
                      variant="standard"
                      {...register('qntsAnosMoraBairroStep8')}
                      InputProps={{
                        readOnly: true,
                        title:
                          'Há quantos anos a família mora no bairro atual:',
                      }}
                    />
                    <TextField
                      label="A família sempre morou no mesmo bairro?"
                      fullWidth
                      variant="standard"
                      {...register('sempreMorouBairroStep8')}
                      InputProps={{
                        readOnly: true,
                        title: 'A família sempre morou no mesmo bairro?',
                      }}
                    />
                    <TextField
                      label="A família possui parentes que residam próximo ao seu local de moradia e que constituam rede de apoio e solidariedade?"
                      fullWidth
                      variant="standard"
                      {...register('parentesProximosMoradiaStep8')}
                      InputProps={{
                        readOnly: true,
                        title:
                          'A família possui parentes que residam próximo ao seu local de moradia e que constituam rede de apoio e solidariedade?',
                      }}
                    />
                    <TextField
                      label="A família possui vizinhos que constituam rede de apoio e solidariedade?"
                      fullWidth
                      variant="standard"
                      {...register('vizinhosRedeApoioStep8')}
                      InputProps={{
                        readOnly: true,
                        title:
                          'A família possui vizinhos que constituam rede de apoio e solidariedade?',
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex', gap: '4rem' }}>
                    <TextField
                      label="A família, ou algum de seus membros, participa de grupos religiosos, comunitários ou outros grupos/instituições que constituam rede de apoio e solidariedade?"
                      fullWidth
                      variant="standard"
                      {...register('gruposReligiososStep8')}
                      InputProps={{
                        readOnly: true,
                        title:
                          'A família, ou algum de seus membros, participa de grupos religiosos, comunitários ou outros grupos/instituições que constituam rede de apoio e solidariedade?',
                      }}
                    />
                    <TextField
                      label="A família, ou algum de seus membros, participa de movimentos sociais, sindicatos, organizações comunitárias, conselhos ou quaisquer outras ações ou instituições voltadas para organização política e defesa de interesses coletivos?"
                      fullWidth
                      variant="standard"
                      {...register('movimentosSociaisStep8')}
                      InputProps={{
                        readOnly: true,
                        title:
                          'A família, ou algum de seus membros, participa de movimentos sociais, sindicatos, organizações comunitárias, conselhos ou quaisquer outras ações ou instituições voltadas para organização política e defesa de interesses coletivos?',
                      }}
                    />
                    <TextField
                      label="A família está cadastrada no Centro de Referência de Assistência Social – CRAS?"
                      fullWidth
                      variant="standard"
                      {...register('familiaCadastrCRAsStep8')}
                      InputProps={{
                        readOnly: true,
                        title:
                          'A família está cadastrada no Centro de Referência de Assistência Social – CRAS?',
                      }}
                    />
                    <TextField
                      label="A família está cadastrada no Centro de Referência Especializado de Assistência Social – CREAS?"
                      fullWidth
                      variant="standard"
                      {...register('familiaCadastrCREAsStep8')}
                      InputProps={{
                        readOnly: true,
                        title:
                          'A família está cadastrada no Centro de Referência Especializado de Assistência Social – CREAS?',
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex', gap: '4rem' }}>
                    <TextField
                      label="A família ou algum integrante participa/cadastrado nos Serviços/Programas abaixo elencados?"
                      fullWidth
                      variant="standard"
                      {...register('familiaProgramasStep8')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                </AccordionDetails>
              </Accordion>
            </form>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  )
}
